import React from "react";
import Slider from "react-slick";
import { Row, Col } from "react-bootstrap";

import Slide1 from '../../Assets/Images/Slideshow/slide_1.jpg';
import Slide2 from '../../Assets/Images/Slideshow/slide_2.jpg';
import Slide3 from '../../Assets/Images/Slideshow/slide_3.jpg';

function Fade() {
    const settings = {
        arrows: false,
        dots: false,
        fade: true,
        infinite: true,
        speed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
        waitForAnimate: false,
        autoplay: true,
        autoplaySpeed: 3000,
        cssEase: "linear",
        adaptiveHeight: true
    };
    return (
        <>
        <section className="no_mob">
            <Row className='home-slider g-0'> 
                <Col>
                    <Slider {...settings}>
                        <div>
                            <div className="home-slider-slide" style={{height: '100vh', backgroundSize: 'cover', backgroundImage: `url(${ Slide1 })`}}  >
                            </div>
                        </div>
                        <div>
                            <div className="home-slider-slide" style={{height: '100vh', backgroundSize: 'cover', backgroundImage: `url(${ Slide2 })`}}  >
                            </div>
                        </div>
                    <div>
                        <div className="home-slider-slide" style={{height: '100vh', backgroundSize: 'cover', backgroundImage: `url(${ Slide3 })`}}  >
                        </div>
                    </div>         
                    </Slider>
                </Col>
        </Row>
      </section>
      </>
    );
  }
  
  export default Fade;