import { useEffect} from 'react';
import { Row, Col, Image } from 'react-bootstrap';

// import 'holderjs/holder';

import home_kitchen_1 from '../../Assets/Images/home_kitchen_1.jpg';
import home_kitchen_2 from '../../Assets/Images/home_kitchen_2.jpg';
import home_kitchen_3 from '../../Assets/Images/home_kitchen_3.jpg';


function Content() {
  
 useEffect(() => {
     document.title = "Woodstock Kitchens";
   }, []);

  return (
    <>
      <section>      
          <Row>
            <Col className='p-0'>
              <Image src={home_kitchen_1} fluid />
            </Col>
            <Col className='p-0 no_mob'>
              <Image src={home_kitchen_2} fluid />
            </Col>
            <Col className='p-0 no_mob'>
              <Image src={home_kitchen_3} fluid />
            </Col>
          </Row>
      </section>

    </>
  );
}

export default Content