import { useEffect} from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import Slider from "react-slick";
import "animate.css/animate.css"
import ContactButton from '../Components/ContactButton'

import image_1 from '../Assets/Images/kitchen_structural_work_1.jpg';
import image_2 from '../Assets/Images/kitchen_structural_work_2.jpg';

import page_header from '../Assets/Images/bathroom_header.jpg';

const Main = () => {
  
 useEffect(() => {
     document.title = "Structural Work | Extend your kitchen";
   }, []);

   const settings = {
    arrows: false,
    dots: false,
    fade: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    waitForAnimate: false,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear",
    adaptiveHeight: true
};

  return (
    <>
    <section className='page_header start' style={{backgroundImage: `url(${ page_header })`}}>
        <h1 className='no_mob'>Structural Work</h1>
    </section>

    <section >
      <Container className='py-3'>
        <Row>
         
          <Col md={5}>
            
            <p>At <strong>Woodstock Kitchens</strong>, we offer more than just beautiful kitchen designs—we also provide expert structural work to enhance or enlarge your kitchen space. Whether you need to knock down a wall, extend your kitchen, or reconfigure the layout, our skilled team is equipped to handle the necessary structural modifications. We ensure all work meets the highest safety and building standards, so you can have peace of mind while transforming your kitchen into a more functional and spacious area.</p>
            <p>Expanding your kitchen isn’t just about aesthetics; it’s about creating a space that suits your lifestyle. Whether you’re seeking an open-concept layout or simply more room for appliances and storage, Woodstock Kitchens can help bring your vision to life with comprehensive structural services. From small adjustments to major renovations, we’re here to ensure your new <a href="/kitchens" >Kitchen</a> is not only beautiful but also built to last.</p>
            <ContactButton />
          </Col>

          <Col md={7}>
            <Slider {...settings}>
              <div>
                  <div className="construction_slider_slide">
                    <Image src={ image_1 } />
                  </div>
              </div>
              <div>
                  <div className="construction_slider_slide">
                    <Image src={ image_2 } />

                  </div>
              </div>
            </Slider>
           
                <blockquote><q>They have transformed our kitchen from a dark hole into a light, bright and large kitchen diner by knocking through two rooms and making it a space we love to entertain.</q></blockquote>
                
            
          </Col>
        </Row>
       
        </Container>
    </section>

    
        
    </>
    );
  }
  
  export default Main;