import { useEffect} from 'react';
import { Row, Col, Image, Button } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';

// import 'holderjs/holder';

import home_kitchen_1 from '../../Assets/Images/home_kitchen_1.jpg';
import home_kitchen_2 from '../../Assets/Images/home_kitchen_2.jpg';
import home_kitchen_3 from '../../Assets/Images/home_kitchen_3.jpg';

import SlideShow from './SlideShow'
import IntroContent from './IntroContent'
import MidContent from './MidContent'
import Testimonials from './Testimonials'
import LatestProjects from './LastestProjects'

const Main = () => {

  
 useEffect(() => {
     document.title = "Woodstock Kitchens";
   }, []);

  return (
    <>
      {/* <SlideShow /> */}

      <IntroContent />
      <MidContent />
      <Testimonials />
      <LatestProjects />
    </>
  );
}

export default Main;