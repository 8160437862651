import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap/dist/react-bootstrap.js";

import { Routes, Route } from 'react-router-dom';

import './App.css';
import "animate.css/animate.css";
import "@fortawesome/fontawesome-free/css/all.css";

import NavBar from './Components/NavBar';
import NoMatch from './Components/NoMatch';
import Footer from './Components/Footer';

import Home from './Pages/Home/Home';
import Kitchens from './Pages/Kitchens';
import Bathrooms from './Pages/Bathrooms';
import Construction from './Pages/Construction';

import Contact from './Pages/Contact';
import Testimonials from './Pages/Testimonials/Testimonials';
import Projects from './Pages/Projects/Projects';
import Project from './Pages/Projects/Project';

const App = () => {

  return (
     <>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/kitchens" element={<Kitchens />} />
        <Route path="/bathrooms" element={<Bathrooms />} />
        <Route path="/testimonials" element={<Testimonials />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/structural-work" element={<Construction />} />
        <Route path="/project/:id" element={<Project />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
      <Footer />
     </>
  );
 };
 
 
export default App;
 
